<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" type="primary" class="mr-16" @click="addGoods"><i class="e-icon add"></i>新增</a-button>
      <a-button size="small" class="mr-16" @click="$message.info('该功能正在开发中')"><a-icon type="download" />导入</a-button>
      <a-button size="small" class="mr-16" @click="$message.info('该功能正在开发中')"><a-icon type="upload" />导出</a-button>
      <a-button size="small" @click="refreshHandle"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <!-- 搜索栏 -->
    <!-- goodsName: '',
        goodsCode: '',
        company: '',
        category: '',
        priceType: '',
        priceMin: '',
        priceMax: '' -->
    <div class="mp-search-wrap-2">
      <a-form-model 
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-row :gutter="8">
          <a-col :span="6">
            <a-form-model-item label="商品名称">
              <a-input v-model="searchForm.goodsName" placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="商品型号">
              <a-input v-model="searchForm.goodsCode" placeholder="请输入商品型号/颜色编码"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="所属企业">
              <a-input v-model="searchForm.company" placeholder="请输入所属企业"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="商品类目">
              <!-- <a-select v-model="searchForm.category" placeholder="请选择商品类目"
                :getPopupContainer="node => node.parentNode || document.body"
              >
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="1">
                  裙子
                </a-select-option>
                <a-select-option value="2">
                  裤子
                </a-select-option>
              </a-select> -->
              <a-cascader
                  :fieldNames="{
                    label: 'tagName',
                    value: 'tagId',
                    children: 'children'
                  }"
                  :options="treeData"
                  change-on-select
                  placeholder="请选择商品类目"
                  v-model="searchForm.category"
                  :getPopupContainer="node => node.parentNode || document.body"
                />
            </a-form-model-item>    
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="6">
            <a-form-model-item label="价格类型">
              <a-select v-model="searchForm.priceType" placeholder="请选择价格类型"
                :getPopupContainer="node => node.parentNode || document.body"
              >
                <a-select-option value="1">
                  出厂价
                </a-select-option>
                <a-select-option value="2">
                  批发价
                </a-select-option>
                <a-select-option value="3">
                  零售价
                </a-select-option>
                <a-select-option value="4">
                  吊牌价
                </a-select-option>
                <a-select-option value="5">
                  尾货价
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="金额范围">
              <a-input-number v-model="searchForm.priceMin" :min="0" :step="0.01" placeholder="￥金额" /> -
              <a-input-number v-model="searchForm.priceMax" :min="0" :step="0.01" placeholder="￥金额" />
            </a-form-model-item>    
          </a-col>
          <a-col :span="12" style="text-align: right">
            <div class="search-btns">
              <a-button class="mp-grey-btn" html-type="submit">
                查询
              </a-button>
              <a-button class="mp-grey-btn" style="margin-left: 8px" @click="resetSearchForm">
                重置
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <!-- table -->
    <!-- 

      @expand="expandHandle"
      @expandedRowsChange="expandedRowKeys => this.expandedRowKeys = expandedRowKeys"
      :expandedRowKeys="expandedRowKeys"
     -->
    <a-table 
      :columns="columns" 
      :data-source="data" 
      rowKey="id"
      @change="handleTableChange"
      :pagination="pagination"
    >
      <div slot="imageUrl" slot-scope="text">
        <span style="width: 36px; height: 36px; background: #eee; display: inline-block">
          <img style="width: 36px; height: 36px;" :src="text">
        </span>
      </div>
      <div slot="operation" slot-scope="record">
        <a-button type="link" @click="toDetail(record)">查看详情</a-button>
        <a-button type="link" @click="toDelete(record)">删除</a-button>
      </div>
      <div slot="expandedRowRender" slot-scope="record">
        <a-table
          :columns="innerColumns"
          :data-source="record.innerData"
          :pagination="false"
          bordered
        > 
          <span slot="imageUrls" slot-scope="text">
            <span
              v-for="(url, index) in text" :key="index" 
              style="width: 36px; height: 36px; background: #eee; display: inline-block; margin-right: 4px;"
            >
              <img style="width: 36px; height: 36px;" :src="url">
            </span>
          </span>
          <span slot="colorCodes" slot-scope="text">
            <span style="margin-right: 8px" v-for="(code, index) in text" :key="index">{{code}}</span>
          </span>
        </a-table>
      </div>
    </a-table>
  </page-head-wrapper>
</template>

<script>
import { goodsList, singleListByGoodsId, removeGoods } from '@/api/goods'
import { listTag, listTree } from '@/api/tag'
import { buildThumborUrl} from '@/utils/tools'
import { mapGetters } from 'vuex'
const columns = [
  { title: '序号', dataIndex: 'sort', align: "center", key: "sort"},
  { title: '商品图片', scopedSlots: { customRender: 'imageUrl'}, dataIndex: 'imageUrl', align: "center", key: "imageUrl"},
  { title: '商品名称', dataIndex: 'name', align: "center", key: "name" },
  { title: '商品型号', dataIndex: 'model', align: "center", key: "model" },
  { title: '所属企业', dataIndex: 'companyName', align: "center", key: "companyName"},
  { title: '所属品牌', dataIndex: 'brand', align: "center", key: "brand" },
  { title: '商品类目', dataIndex: 'categoryName', align: "center", key: "categoryName" },
  { title: '价格', dataIndex: 'price', align: "center", key: "price" },
  { title: '操作',  scopedSlots: { customRender: 'operation' }, align: "center", key: "operation" },
];
// const data = [];
// for (let i = 0; i < 3; ++i) {
//   data.push({
//     key: i,
//     sort: i+1,
//     pic: '图片地址',
//     name: '商品名字',
//     code: 'x2332332',
//     company: '某某企业',
//     brand: '特步',
//     catogory: '裤子',
//     price: '100-200'
//   });
// }

const innerColumns = [
  { title: '单品图片', align: "center", width: '25%', dataIndex: 'imageUrls', scopedSlots: { customRender: 'imageUrls'}, key: 'imageUrls' },
  { title: '单品名称', align: "center", width: '25%', dataIndex: 'name', key: 'name' },
  { title: '单品型号', align: "center", width: '15%', dataIndex: 'model', key: 'model'},
  { title: '颜色编码', align: "center", width: '35%', dataIndex: 'colorCodes', scopedSlots: { customRender: 'colorCodes'}, key: 'colorCodes'},
];

const innerData = [];
for (let i = 0; i < 3; ++i) {
  innerData.push({
    key: i,
    pic: '图片地址',
    name: 'This is 单品 名称',
    singleCode: 'x4324544324',
    colorCode: '324324err 34244324r 432532'
  });
}

export default {
  name: 'static-data-goods-list-list',
  keep_alive_tab: true,
  data() {
    return {
      // 搜索表单
      searchForm: {
        goodsName: '',
        goodsCode: '',
        company: '',
        category: [],
        priceType: '1',
        priceMin: null,
        priceMax: null
      },

      data: [],
      columns,
      innerColumns,
      expandedRowKeys: [],
      treeData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },
    }
  },
  computed: {
    ...mapGetters(['adminId'])
  },
  created() {
    this.getGoodsTag()
    this.goodsList()
  },
  methods: {
    // 获取商品类目标签
    async getGoodsTag() {
      try {
        let res1 = await listTag({parentId: 0})
        let goodsTag = res1.data.find(item => item.tagName == '商品标签')
        let res2 = await listTree({tagId: goodsTag.tagId})
        console.log(res2.data)
        let catogoryTree = res2.data[0].children.find(item => item.tagName == '商品类目').children
        this.treeData = catogoryTree
      } catch (error) {
        this.$message.error('获取类目信息失败')
      }
    },
    // 获取商品列表
    goodsList() {
      let params = {
        current: this.pagination.current,
        size: this.pagination.pageSize,
      }
      let categoryId = ''
      if (this.searchForm.category && this.searchForm.category.length >=1) {
        categoryId = this.searchForm.category.slice(-1)[0]
      }
      let name = this.searchForm.goodsName.trim()
      let model = this.searchForm.goodsCode.trim()
      let companyName = this.searchForm.company.trim()
      if (name) params['name'] = name
      if (model) params['model'] = model
      if (companyName) params['companyName'] = companyName
      if (categoryId) params['categoryId'] = categoryId
      if (this.searchForm.priceType == '1') {
        params['startExFactoryPrice'] = this.searchForm.priceMin
        params['endExFactoryPrice'] = this.searchForm.priceMax
      } else if(this.searchForm.priceType == '2') {
        params['startWholesalePrice'] = this.searchForm.priceMin
        params['endWholesalePrice'] = this.searchForm.priceMax
      } else if (this.searchForm.priceType == '3') {
        params['startRetailPrice'] = this.searchForm.priceMin
        params['endRetailPrice'] = this.searchForm.priceMax
      } else if (this.searchForm.priceType == '4') {
        params['startTagPrice'] = this.searchForm.priceMin
        params['endTagPrice'] = this.searchForm.priceMax
      } else if (this.searchForm.priceType == '5') {
        params['startTailPrice'] = this.searchForm.priceMin
        params['endTailPrice'] = this.searchForm.priceMax
      }
      console.log(params)
      goodsList(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.expandedRowKeys = []
          setTimeout(() => {
            this.data = res.data.records.map((item, index) => {
              let images = item.images.split(',')
              let imageUrl = ''
              if (images[0]) imageUrl = buildThumborUrl(images[0], {width: 100, height: 100})
              let price = item.minPrice + '-' + item.maxPrice

              let innerData = item.subItemVOList.map((item, index) => {
                let imageUrls = item.colorImages.split(',')
                imageUrls = imageUrls.map(url => {
                  return buildThumborUrl(url, {width: 100, height: 100})
                })
                let colorCodes = item.colorCodes.split(',')
                return{
                  ...item,
                  key: index + 1,
                  imageUrls,
                  colorCodes
                }
              })
              return {
                ...item,
                sort: index+1,
                imageUrl,
                price,
                innerData
              }
            })
            this.pagination.total = res.data.total
          },0)
          console.log(this.data);
        } else {
          this.$message.error(res.msg || '网络错误')
        }
        
      }).catch(err => {
        console.error(err)
      })
    },
    expandHandle(expanded, record) {
      console.log(expanded, record)
      console.log(this.expandedRowKeys)
      if (expanded && record.innerData.length == 0) {
        singleListByGoodsId({itemId: record.id}).then(res => {
          if (res.code == 200) {
            record.innerData = res.data.map((item, index) => {
              let imageUrls = item.images.map(url => {
                return buildThumborUrl(url, {width: 100, height: 100})
              })
              return{
                ...item,
                key: index + 1,
                imageUrls,
              }
            })
          } else {
            this.$message.error(res.msg || '网络错误')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      this.pagination.current = 1
      this.goodsList()
    },
    // 重置搜索
    resetSearchForm() {
      console.log('resetSearchForm')
      this.pagination.current = 1
      this.searchForm.goodsName = ''
      this.searchForm.goodsCode = ''
      this.searchForm.category = []
      this.searchForm.priceType = '1'
      this.searchForm.priceMin = null
      this.searchForm.priceMax = null
      this.goodsList()
    },
    handleTableChange(pagination) {
      console.log(pagination)
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.goodsList()
    },
    addGoods() {
      this.pushTabPage({
        path: '/static-data/goods/list/add'
      })
    },
    toDetail(record) {
      this.pushTabPage({
        path: '/static-data/goods/list/detail?id=' + record.id
      })
    },
    toDelete(record) {
      this.$confirm({
        title: '提示',
        content: '确定删除该商品吗',
        centered: true,
        onOk: () => {
          this.$showLoading()
          removeGoods({
            adminId: this.adminId,
            itemId: record.id
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.goodsList()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        },
        onCancel: () => {
          console.log('Cancel');
        },
      })
    },
    // 刷新
    refreshHandle() {
      this.resetSearchForm()
    }
  }
}
</script>

<style scoped>

</style>